import React from 'react'

const Info = () => {
  return (
    <div className="about__info grid">
        <div className="about__box">
        <i class='bx bxs-graduation about__icon'></i>
            <h3 className="about__title">Major</h3>
            <span className="about__subtitle">Computer Science</span>
        </div>

        <div className="about__box">
        <i class='bx bx-briefcase-alt about__icon'></i>
            <h3 className="about__title">Completed</h3>
            <span className="about__subtitle">5+ Projects</span>
        </div>

        <div className="about__box">
        <i class='bx bxs-bolt about__icon'></i>
            <h3 className="about__title">Passionate</h3>
            <span className="about__subtitle">Hardworking</span>
        </div>
    </div>
  )
}

export default Info