import React from 'react';

const Social = () => {
  return (
    <div className="home__social">
        <a href="https://github.com/mmitch1/" className="home__social-icon" target="_blank" rel="noreferrer">
            <i className="uil uil-github-alt"></i>
        </a>

        <a href="http://www.linkedin.com/in/michaelkmitchell" className="home__social-icon" target="_blank" rel="noreferrer">
            <i className="bx bxl-linkedin"></i>
        </a>

        <a href="http://www.discordapp.com/users/770559726568079370" className="home__social-icon" target="_blank">
            <i className="bx bxl-discord"></i>
        </a>

        
    </div>
  );
};

export default Social;