// import Work1 from "../../assets/work1.jpg";
import Ion from "../../assets/ion.jpg";
import Surf from "../../assets/surf-poster.png";
import Isef from "../../assets/isef-poster.gif";
import ED from "../../assets/encrypt-decrypt.gif";
import ISys from "../../assets/inventory-system.gif";
import HDAP from "../../assets/heart-predict.png";
import FSTQ from "../../assets/fastq.png";


// import Work2 from "../../assets/work2.jpg";

export const projectsData = [
    {
        id: 1,
        image: Ion,
        title: 'Ion Project',
        link: 'https://www.ricethresher.org/article/2022/10/rices-innovation-district-joins-third-ward-community',
        category: 'web',
    },
    {
        id: 2,
        image: FSTQ,
        title: 'FASTQ Analyzer',
        link: 'https://mmitch1.github.io/fastqall/',
        category: 'web',
    },
    {
        id:3,
        image: HDAP,
        title: 'Heart Disease Analysis & Predictor',
        category: 'Python',
        link: 'https://github.com/mmitch1/Heart.Prediction',
    },
    {
        id: 4,
        image: Isef,
        title: 'COVID Predictor',
        link: 'https://projectboard.world/isef/project/cbio009t---predicting-covid-19-severity-with-ai?utm_source=email_comment_other',
        category: 'research',
    },
    {
        id: 5,
        image: ISys,
        title: 'Inventory System',
        category: 'C++',
        link: 'https://github.com/mmitch1/Inventory-System',
    },
    {
        id: 6,
        image: ED,
        title: 'Encrypt-Decrypt',
        category: 'Java',
        link: 'https://github.com/mmitch1/Encrypt-Decrypt-Java',
    },
    {
        id: 7,
        image: Surf,
        title: 'Gene Regulation Project',
        link: 'https://drive.google.com/file/d/1rbYSOTahzvcmSjQuAPxNDf-xNungly6x/view?usp=sharing',
        category: 'research',
    },
];

export const projectsNav = [
    {
        name: 'all',
    },
    {
        name: 'web',
    },
    {
        name: 'python',
    },
    {
        name: 'c++',
    },
    {
        name: 'java',
    },
    {
        name: 'research',
    },
];